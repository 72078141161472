import type {
  NextPage,
  GetServerSideProps,
  InferGetServerSidePropsType,
} from 'next'
import { encodeParseQuery, useParseQuery } from '@parse/react-ssr'
import { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import Filter from '../components/filter/'
import Map from '../components/map/'
import Jobs from '../components/jobs'

import type { RootState } from '../redux/store'

// import type FilterData from '../models/FilterData'

import {
  setAddress,
  setLocation,
  setRadius,
  setHoursPerWeekMin,
  setHoursPerWeekMax,
  setHourlyRate,
  setJobTypes,
  setJobCategories,
} from '../redux/slices/filterDataSlice'

// const initialState: FilterData = {
//   address: '10117 Berlin, Germany',
//   location: {
//     lat: 52.521749,
//     lng: 13.397671,
//   },
//   radius: 50,
//   hoursPerWeek: [1, 48],
//   hourlyRate: 0,
//   jobTypes: ['parttimejob', 'minijob', 'temporaryjob', 'weekendjob', 'internship', 'studentjob'],
//   jobCategories: ['fastfood', 'restaurant', 'bar', 'cafe', 'fashion', 'groceries', 'logistik', 'others', 'service']
// }

const Home: NextPage = ({
  parseQuery,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  // console.log('query', parseQuery)
  const [enabled, setEnabled] = useState<boolean>(false);

  const { isLoading, results, error, reload } = useParseQuery(parseQuery, {
    enabled: enabled,
    enableLiveQuery: false,
    // enableLocalDatastore: false,
    initialLoad: {
      // TODO: this is very bad typing, but we need this null to decide if we have no results yet or if we have zero results
      results: null as unknown as [], count: 0
    }
  })

  useEffect(() => {
      setEnabled(true);
  }, []);

  const filterData = useSelector((state: RootState) => state.filterData)

  const dispatch = useDispatch()

  const router = useRouter()

  // const [radius, setRadius] = useState(initialState.radius)
  // const [address, setAddress] = useState(initialState.address)
  // const [location, setLocation] = useState(initialState.location)
  // const [hourlyRate, setHourlyRate] = useState(initialState.hourlyRate)
  // const [hoursPerWeek, setHoursPerWeek] = useState(initialState.hoursPerWeek)
  // const [hoursPerWeekMin, setHoursPerWeekMin] = useState(initialState.hoursPerWeek[0])
  // const [hoursPerWeekMax, setHoursPerWeekMax] = useState(initialState.hoursPerWeek[1])
  // const [jobTypes, setJobTypes] = useState(initialState.jobTypes)
  // const [jobCategories, setJobCategories] = useState(initialState.jobCategories)

  useEffect(() => {
    const {
      // filter,
      radius,
      address,
      lat,
      lng,
      hourlyRate,
      hoursMax,
      hoursMin,
      jobTypes,
      jobCategories,
    } = router.query

    // console.log(filterData.radius, radius)
    // console.log(filterData.address, address)
    // console.log(filterData.location, lat, lng)
    // console.log(filterData.hourlyRate, hourlyRate)
    // console.log(filterData.hoursPerWeek, hoursMin, hoursMax)
    // console.log(filterData.jobTypes, jobTypes)
    // console.log(filterData.jobCategories, jobCategories)

    // if(!filter) {
    // dispatch(setFilter(filter ? filter : null))
    if (radius && filterData.radius.toString(10) !== radius)
      dispatch(setRadius(Number.parseInt(radius as string, 10)))
    if (address && filterData.address !== address) dispatch(setAddress(address))
    if (
      lat &&
      lng &&
      filterData.location.lat.toString(10) !== lat &&
      filterData.location.lng.toString() !== lng
    )
      dispatch(
        setLocation({
          lat: Number.parseFloat(lat as string),
          lng: Number.parseFloat(lng as string),
        })
      )
    if (hourlyRate && filterData.hourlyRate.toString(10) !== hourlyRate)
      dispatch(setHourlyRate(Number.parseInt(hourlyRate as string, 10)))
    if (hoursMin && filterData.hoursPerWeek[0].toString(10) !== hoursMin)
      dispatch(setHoursPerWeekMin(Number.parseInt(hoursMin as string, 10)))
    if (hoursMax && filterData.hoursPerWeek[1].toString(10) !== hoursMax)
      dispatch(setHoursPerWeekMax(Number.parseInt(hoursMax as string, 10)))
    if (jobTypes && filterData.jobTypes.length !== jobTypes.length)
      dispatch(setJobTypes((jobTypes as string).split(',')))
    if (
      jobCategories &&
      filterData.jobCategories.length !== jobCategories.length
    )
      dispatch(setJobCategories((jobCategories as string).split(',')))
    // }

    // if(radius) setRadius(Number.parseInt(radius as string, 10))
    // if(address) setAddress(address)
    // if(lat && lng) setLocation({
    //   lat: Number.parseFloat(lat as string),
    //   lng: Number.parseFloat(lng as string)
    // })
    // if(hourlyRate) setHourlyRate(Number.parseInt(hourlyRate as string, 10))
    // if(hoursMax) setHoursPerWeekMax(Number.parseInt(hoursMax as string, 10))
    // if(hoursMin) setHoursPerWeekMin(Number.parseInt(hoursMin as string, 10))
    // if(jobTypes) setJobTypes((jobTypes as string).split(','))
    // if(jobCategories) setJobCategories((jobCategories as string).split(','))
    // }, [dispatch, router.query])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // console.log(filterData)
    // console.log('results:', results)

    const query: any = {
      radius: filterData.radius,
      address: filterData.address,
      lat: filterData.location.lat,
      lng: filterData.location.lng,
      hourlyRate: filterData.hourlyRate,
      hoursMax: filterData.hoursPerWeek[1],
      hoursMin: filterData.hoursPerWeek[0],
      jobTypes: filterData.jobTypes.join(','),
      jobCategories: filterData.jobCategories.join(','),
    }

    // const { filter } = router.query
    // if(filter)
    //   query.filter = filter

    // console.log('query:', query)

    try {
      router.replace({ query })
    } catch {
      // console.log('router.replace!')
    }
  }, [filterData, results]) // eslint-disable-line

  // useEffect(() => {
  //   console.log(results)
  //   router.replace({
  //     query: {
  //       radius: radius,
  //       address: address,
  //       lat: location.lat,
  //       lng: location.lng,
  //       hourlyRate: hourlyRate,
  //       hoursMax: hoursPerWeek[1],
  //       hoursMin: hoursPerWeek[0],
  //       jobTypes: jobTypes.join(','),
  //       jobCategories: jobCategories.join(',')
  //     },
  //   })
  // }, [radius, address, location, location, hourlyRate, hoursPerWeekMax, hoursPerWeekMin, jobTypes, jobCategories])

  return (
    <>
      <Filter />
      <Map jobs={results} />
      <Jobs jobs={results} loading={isLoading} />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (params) => {
  // console.log("getServerSideProps")

  const {
    // company,
    // filter,
    radius,
    address,
    lat,
    lng,
    jobTypes,
    jobCategories,
    hoursMin,
    hoursMax,
    hourlyRate,
  } = params.query

  if (
    // !filter ||
    !radius ||
    !address ||
    !lat ||
    !lng ||
    typeof jobTypes === 'undefined' ||
    typeof jobCategories === 'undefined' ||
    !hoursMin ||
    !hoursMax ||
    !hourlyRate
  ) {
    return {
      redirect: {
        destination: encodeURI(
          `/?` +
            [
              // `filter=${filter || company}`,
              `radius=${radius || 50}`,
              `address=${address || '10178 Berlin, Deutschland'}`,
              `lat=${lat || 52.521749}`,
              `lng=${lng || 13.397671}`,
              `jobTypes=${
                jobTypes ??
                [
                  'parttimejob',
                  'minijob',
                  'temporaryjob',
                  'weekendjob',
                  'internship',
                  'studentjob',
                ].join(',')
              }`,
              `jobCategories=${
                jobCategories ??
                [
                  'fastfood',
                  'restaurant',
                  'bar',
                  'cafe',
                  'fashion',
                  'groceries',
                  'logistik',
                  'others',
                  'service',
                ].join(',')
              }`,
              `hoursMin=${hoursMin || 1}`,
              `hoursMax=${hoursMax || 48}`,
              `hourlyRate=${hourlyRate || 0}`,
            ].join('&')
        ),
        permanent: false,
      },
      props: {},
    }
  }

  const parseQuery = new Parse.Query('Job')

  if (params.query.radius) {
    const location = new Parse.GeoPoint({
      latitude: Number(params.query.lat),
      longitude: Number(params.query.lng),
    })

    const jobTypes: any =
      typeof params.query.jobTypes === 'string'
        ? params.query.jobTypes.split(',')
        : params.query.jobTypes
    const jobCategories: any =
      typeof params.query.jobCategories === 'string'
        ? params.query.jobCategories.split(',')
        : params.query.jobCategories

    parseQuery.include('contact')
    parseQuery.include('company')
    parseQuery.withinKilometers(
      'location',
      location,
      Number(params.query.radius),
      true
    )
    parseQuery.greaterThanOrEqualTo(
      'hoursPerWeek',
      Number(params.query.hoursMin)
    )
    parseQuery.lessThanOrEqualTo('hoursPerWeek', Number(params.query.hoursMax))
    parseQuery.greaterThanOrEqualTo(
      'hourlyRate',
      Number(params.query.hourlyRate)
    )
    parseQuery.containedIn('jobTypes', jobTypes)
    parseQuery.containedIn('jobCategory', jobCategories)
    parseQuery.equalTo('isActive', true)
    parseQuery.limit(1000)
  }

  return {
    props: {
      parseQuery: await encodeParseQuery(parseQuery),
    },
  }
}

export default Home
